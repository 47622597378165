import React from "react";

import _ from "lodash";
import moment from "moment";

import { equalsAny } from "../filters";

import {
  itemAccessors,
  priorityCodes,
  requiredColumnsIdOrder,
  editableColumnsIdOrder,
  batchColumnsIdOrder,
  expired,
  expiry_this_week,
  expiry_next_week,
  expiry_two_weeks,
  expiry_three_weeks,
  expiry_three_plus_weeks,
} from "../constants";

import { toSentenceCaseFromSnakeCase } from "../format";

import BatchStatusCell from "../../components/OrdersTable/BatchStatusCell/BatchStatusCell";

import { toExcelNumberFormatWithDecimals } from "../format";

const excludedFields = [
  itemAccessors.reasonCode,
  itemAccessors.variance,
  itemAccessors.additional,
  itemAccessors.bufferData,
];

// Requires the t function from react-i18next to be passed in
export const mapAccessorsToColumns = (
  t,
  accessors,
  ActualOrderCell,
  VarianceCell,
  datasetColumns,
  dynamicFieldNamesTitlesMap,
) => {
  const priorityKeys = Object.keys(priorityCodes);

  return accessors
    .filter((accessor) => !excludedFields.includes(accessor))
    .map((accessor) => {
      // if accessor corresponds to unknown colum, i.e. an "additional field", t() will return its argument
      const translated = t(`ordersTable.columns.${accessor}`);
      // fall back to original string from columns in dataset if no translation is found
      // for dynamic fields, we set the fallback to the title from the map if present
      const fallback = dynamicFieldNamesTitlesMap[accessor] ||
        datasetColumns[accessor] ||
        toSentenceCaseFromSnakeCase(accessor);
      
      const column = {
        accessor: accessor,
        Header:
          translated === `ordersTable.columns.${accessor}`
            ? fallback
            : translated,
      };

      // Add custom filter for every column if it is necessary
      if(accessor === "location_code_concatenation"){
        column.filter = "multipleOptions";
      }else{
        column.filter = "equalsAny";
      }

      // Generic cell with rounded numbers
      column.Cell = ({ cell: { value } }) => {   
        if(accessor === "unit_cost"){
          return new Intl
                        .NumberFormat("en-US", {opts:{currency: "USD"}, 
                                                minimumFractionDigits:2, 
                                                maximumFractionDigits:4})
                        .format(value)
        }
        if (_.isNumber(value)) {
          return toExcelNumberFormatWithDecimals(value)
        }
        return value;
      };
      
      // required to yield translations of priority codes
      if (column.accessor === itemAccessors.priority) {
        column.Cell = ({ cell: { value } }) => {
          return t(priorityKeys[value]);
        };
      }

      if (column.accessor === itemAccessors.worstBatchStatus) {
        column.Cell = BatchStatusCell;
      }

      if (column.accessor === itemAccessors.materialMethod) {
        column.Cell = ({ cell: { value } }) => {
          if (value === "-") {
            return (
              <span className="unassigned-text-warning">
                {t("ordersTable.method.unassigned")}
              </span>
            );
          }
          return t(`ordersTable.method.${value}`);
        };
      }

      if (
        _.includes(
          [itemAccessors.netFlowPriority, itemAccessors.currentOnHandAlert],
          column.accessor
        )
      ) {
        column.sortType = 'number';
        column.Cell = ({ cell: { value } }) => {        
          if (_.isNumber(value)) {
            return toExcelNumberFormatWithDecimals(value, {style:"percent"})
          }
          return value;
        };
      }

      if (column.accessor === itemAccessors.suggestedOrder) {
        column.filter = (rows, id, filterValue) => {
          if (
            _.isObject(filterValue) &&
            filterValue.type === "globalFilter" &&
            _.has(filterValue, "threshold")
          ) {
            const { threshold } = filterValue;
            return rows.filter((row) => {
              const rowValue = row.values[id];
              if (_.isNil(threshold)) {
                return true;
              }
              if (threshold === 0) {
                return rowValue === 0;
              } else {
                return rowValue > 0;
              }
            });
          } else {
            return equalsAny(rows, id, filterValue);
          }
        };
      }

      if (column.accessor === itemAccessors.actualOrder) {
        column.Cell = ActualOrderCell;
      }

      if (column.accessor === itemAccessors.orderVariance) {
        column.Cell = VarianceCell;
      }

      return column;
    });
};

export const mapItemsToTableData = (items) => {
  return items.map((item) =>
    _.pick(
      item,
      Object.keys(item).filter((key) => !excludedFields.includes(key))
    )
  );
};

export const mapSavedLayouts = (layouts) =>
  layouts.map((layout) => layout.name);

export const getPriorityColumn = (flatColumns) => {
  return _.find(flatColumns, (column) => column.id === itemAccessors.priority);
};

export const getSuggestedOrderColumn = (flatColumns) => {
  return _.find(
    flatColumns,
    (column) => column.id === itemAccessors.suggestedOrder
  );
};

export const divisionBySmallNumber = (dividend, divisor)=>{
  let value = Math.floor(dividend / divisor);
  if(!isFinite(value)){
    return "-"
  }
  if(isFinite(value)){
    return value
  }
}

export const mapResultsRecordsToTableData = (records, workspaceTimezone) => {
  return records.map((record) => {
    const mapped = _.pick(
      record,
      Object.keys(record).filter((key) => !excludedFields.includes(key) && key.length !== 0)
    );

    Object.keys(mapped).forEach((key) => {
      if (_.isNil(mapped[key])) {
        mapped[key] = "-";
      }
    });

    const projected_stock_out = divisionBySmallNumber(mapped.on_hand_stock, mapped.average_daily_usage)

    let worstBatchStatus = null;
    if (_.has(mapped, "worst_batch_expiry_date")) {
      worstBatchStatus = computeBatchStatus(mapped.worst_batch_expiry_date, workspaceTimezone);
      return {
        ...mapped,
        projected_stock_out: _.isNaN(Number(projected_stock_out))
          ? "-"
          : projected_stock_out,
        worst_batch_status: worstBatchStatus,
        order_variance: {
          delta: record.variance,
          reasonCode: record.reason_code,
        },
      };
    }

    return {
      ...mapped,
      projected_stock_out: _.isNaN(Number(projected_stock_out))
        ? "-"
        : projected_stock_out,
      order_variance: {
        delta: record.variance,
        reasonCode: record.reason_code,
      },
    };
  });
};

export const mapOrderTableRecordToTableRow = (record, workspaceTimezone) => {
  const mapped = _.pick(
    record,
    Object.keys(record).filter((key) => !excludedFields.includes(key))
  );

  Object.keys(mapped).forEach((key) => {
    if (_.isNil(mapped[key])) {
      mapped[key] = "-";
    }
  });

  let projected_stock_out = divisionBySmallNumber(mapped.on_hand_stock, mapped.average_daily_usage)

  let worstBatchStatus = null;
  if (_.has(mapped, "worst_batch_expiry_date")) {
    worstBatchStatus = computeBatchStatus(mapped.worst_batch_expiry_date, workspaceTimezone);
    return {
      ...mapped,
      projected_stock_out: _.isNaN(Number(projected_stock_out))
        ? "-"
        : projected_stock_out,
      worst_batch_status: worstBatchStatus,
      order_variance: {
        delta: record.variance,
        reasonCode: record.reason_code,
      },
      buffer_zone_color: getBufferZoneColor(record)
    };
  }

  return {
    ...mapped,
    projected_stock_out: _.isNaN(Number(projected_stock_out))
      ? "-"
      : projected_stock_out,
    order_variance: {
      delta: record.variance,
      reasonCode: record.reason_code,
    },
    buffer_zone_color: getBufferZoneColor(record)
  };
};

export const mapRowsToRecordsObject = (rows) => {  
  const values = rows.map((row) => row.values);
  return {
    records: values.map((entry) => {
      const variance = entry.order_variance.delta || 0;
      const reasonCode = entry.order_variance.reasonCode || "";

      return {
        ...entry,
        order_variance: undefined,
        variance,
        reason_code: reasonCode,
      };
    }),
  };
};

export const mapItemsToRecordsObject = (items) => {
  return{
    records : items.map((item) => {
      const variance = item.order_variance.delta || 0;
      const reasonCode = item.order_variance.reasonCode || "";

      return {
        ...item,
        order_variance: undefined,
        variance,
        reason_code: reasonCode,
      }    
    }), 
  };
};

export const mapToRecordsObject = (records) => {
  return {
    records: records.map((entry) => {
      const variance = entry.order_variance.delta || 0;
      const reasonCode = entry.order_variance.reasonCode || "";

      return {
        ...entry,
        order_variance: undefined,
        variance,
        reason_code: reasonCode,
      };
    }),
  };
};

export const mapCustomOrderTableLayout = (layout) => ({
  id: layout.id,
  name: layout.name,
  initialState: JSON.parse(
    layout.initial_state.replace('False', 'false').replace('True', 'true').replace(/"/g, '\\"').replace(/'/g, '"')
  ),
  isDeletable: true,
  isOverwriteable: true,
});

export const getSortedColumns = (unsortedColumns, hasBatchData) => {
  const nonAdditional = [
    ...requiredColumnsIdOrder,
    ...batchColumnsIdOrder,
    ...editableColumnsIdOrder,
  ];
  const additionalColumnIds = unsortedColumns
    .filter((col) => !_.includes(nonAdditional, col.accessor))
    .map((col) => col.accessor);

  let columnsIdOrder = hasBatchData
    ? [
      ...requiredColumnsIdOrder,
      ...additionalColumnIds,
      ...batchColumnsIdOrder,
      ...editableColumnsIdOrder,
    ]
    : [
      ...requiredColumnsIdOrder,
      ...additionalColumnIds,
      ...editableColumnsIdOrder,
    ];

  const dynamicFieldsColumnIds = columnsIdOrder.filter((col) => col.endsWith("__dyf")).sort();
  columnsIdOrder = [
    ..._.difference(columnsIdOrder, dynamicFieldsColumnIds),
    ...dynamicFieldsColumnIds
  ];

  return _.sortBy(unsortedColumns, function (column) {
    return columnsIdOrder.indexOf(column.accessor);
  });
};

export const computeBatchStatus = (expiryDate, workspaceTimezone) => {
  const expiry = moment(expiryDate, "DD-MM-YYYY")

  const todayDateString = moment.tz(workspaceTimezone).format('DD/MM/YYYY')
  const today = moment(todayDateString, 'DD/MM/YYYY').utc(true)

  const differenceInDays = moment(expiry, 'DD/MM/YYYY').diff(today, "days", true);

  if (differenceInDays <= 0) {
    return expired;
  } else if (differenceInDays <= 7) {
    return expiry_this_week;
  } else if (differenceInDays <= 7 * 2) {
    return expiry_next_week;
  } else if (differenceInDays <= 7 * 3) {
    return expiry_two_weeks;
  } else if (differenceInDays <= 7 * 4) {
    return expiry_three_weeks;
  } else {
    return expiry_three_plus_weeks;
  }
};

export const mapBatchData = (batchData, workspaceTimezone) => {
  if (_.isNil(batchData) || _.isEmpty(batchData)) {
    return null;
  }
  const mapped = {};
  for (const entry of Object.entries(batchData)) {
    mapped[entry[0]] = entry[1].map((batch) => ({
      ...batch,
      status: computeBatchStatus(batch.batch_expiry_date, workspaceTimezone),
    }));
  }

  return {
    ...mapped,
  };
};

// Gived a record object (row data) check return color of buffer zone by on-hand stock value
export const getBufferZoneColor = (record)=>{
  // This coditional happens when actual order value is updated
  if(!record.top_of_grey && record.top_of_red==="-" && record.top_of_yellow==="-" && record.top_of_green==="-"){
    return "confirm_to_calculate"
  }
  // This conditional happens when buffer is not calculated, maybe because user hadn't assign group yet
  if(!record.top_of_grey && !record.top_of_red && !record.top_of_yellow && !record.top_of_green){
    return "confirm_to_calculate"
  }
  const bufferZoneCode = [
    "grey_zone", 
    "red_zone", 
    "yellow_zone", 
    "green_zone", 
    "blue_zone"
  ]

  const zoneRangeList = [0, record.top_of_grey, record.top_of_red, record.top_of_yellow, record.top_of_green]

  const onHandStock = record.on_hand_stock
  // This conditional happens when buffers is calculated but on-hand stock is zero
  if(onHandStock === 0){
    return "stock_out"
  }

  const indexZone = [1, 2, 3, 4].filter((index)=>{
      const previousValue = zoneRangeList[index - 1]
      const currentValue = zoneRangeList[index]
      return onHandStock > previousValue && onHandStock <= currentValue 
  })
  const zoneName = bufferZoneCode[indexZone-1]
  return zoneName ? zoneName : bufferZoneCode[4]
}

export const recordsWithOnHandStockColors = (records)=>{
  return records.map((record)=>({...record, buffer_zone_color:getBufferZoneColor(record)}))
}

export const orderItemsByZoneColors = (items, orderColorArray, t)=>{
  let res1 = []
  for(let step= 0; step < orderColorArray.length; step++){
    const res = items
                .filter((item)=> 
                  t("ordersTable.columns."+item.buffer_zone_color) === orderColorArray[step].bufferZoneColor)
    res1 = res1.concat(res)
  }

  return res1
}

export const formatDynamicFieldValues = (items, rowDynamicFieldData, dynamicFieldIndex) => {
  const editingRowContainingDynamicField = (
    !_.isNil(dynamicFieldIndex) &&
    !(dynamicFieldIndex === -1) &&
    Object.keys(rowDynamicFieldData).length !== 0 && 
    !_.isNil(rowDynamicFieldData) &&
    !_.isUndefined(rowDynamicFieldData)
  )
  if(editingRowContainingDynamicField){
    Object.keys(rowDynamicFieldData[dynamicFieldIndex]).forEach((dynamicField) => {
      const formattedDynamicFieldValues = toExcelNumberFormatWithDecimals(items[dynamicFieldIndex][dynamicField]);
      const notNaNStr = isNaN(formattedDynamicFieldValues) && !(formattedDynamicFieldValues === "NaN") 
      if(notNaNStr){
        items[dynamicFieldIndex][dynamicField] = formattedDynamicFieldValues
      }
    });
  }
}